import moment from "moment";
import 'moment/locale/es';

moment.locale('es');

export default function Mails({ id, email, date }) {

    const relativeTime = moment(date).fromNow();
    const formattedDate = moment(date).format('LL');

    return (

        <div className="__mail_box" id={`mailbox-${id}`}>
            <div className="__col1">
                <figure className="__image">
                    <img src={`https://ui-avatars.com/api/?name=${email}&size=50`} alt={`Avatar del correo de ${email}`} />
                </figure>
                <div>
                    <h3>{email}</h3>
                    <p>{formattedDate} • {relativeTime}</p>
                </div>
            </div>
        </div>

    )

}