import { createContext, useContext, useEffect, useState } from "react";

const DBContext = createContext();

export const DBProvider = ({ children }) => {

    const [ redes, setRedes ] = useState([]);
    const [ projects, setProjects ] = useState([]);
    const [ articles, setArticles ] = useState([]);

    const [ isLoading, setIsLoading ] = useState(false);
    const [ errorMessages, setErrorMessages ] = useState('');

    const handleListRedes = async () => {

        try {
            
            const response = await fetch('https://api.northenergyeirl.com/dashboard/network/')

            if (!response.ok) {
                throw new Error("Error fetching network dashboard response");
            }

            const data = await response.json();

            if (data.ok) {
                setRedes(data.data)
            } else {
                console.log(data.message);
            }

        } catch (error) {
            console.error(error.message);
        }

    }

    const handleListProjects = async () => {
        try {

            setIsLoading(true);
            
            const response = await fetch('https://api.northenergyeirl.com/dashboard/?projects');

            if (!response.ok) {
                throw new Error('Could not find articles')
            }

            const data = await response.json();

            if (data.ok) {
                setProjects(data.projects)
            } else {
                setErrorMessages(data.messages)
            }

        } catch (error) {
            setErrorMessages(error.message);
        } finally {
            setIsLoading(false);
        }
    }

    const handleListArticles = async () => {

        try {
            
            setIsLoading(true);

            const response = await fetch(`https://api.northenergyeirl.com/dashboard/?blogs`);

            if (!response.ok) {
                throw new Error('Could not find articles')
            }

            const data = await response.json();

            if (data.ok) {
                setArticles(data.articles);
            } else {
                setErrorMessages(data.messages)
            }

        } catch (error) {
            setErrorMessages(error.message);
        } finally {
            setIsLoading(false);
        }

    }

    useEffect(() => {
        if (redes.length === 0 || projects.length === 0 || articles.length === 0) {
            handleListRedes();
            handleListArticles();
            handleListProjects();
        }
    }, [redes.length, projects.length, articles.length])

    const contextValue = {
        redes,
        projects,
        articles,
        isLoading,
        errorMessages,
        handleListRedes,
        handleListArticles,
        handleListProjects,
    }

    return (
        <DBContext.Provider value={contextValue}>{children}</DBContext.Provider>
    )

}

export const useDB = () => useContext(DBContext);