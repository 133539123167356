import { createContext, useContext, useState } from "react";

const UIContext = createContext();

export const UIProvider = ({ children }) => {

    const [ isAlert, setIsAlert ] = useState({
        type: '',
        message: ''
    });

    const handleSendAlert = (type, message) => {
        setIsAlert({
            type: type,
            message: message
        })
    }

    const contextValue = {
        isAlert,
        handleSendAlert
    }

    return (
        <UIContext.Provider value={contextValue}>{children}</UIContext.Provider>
    )

}

export const useUI = () => useContext(UIContext);