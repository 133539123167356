import { useNavigate } from 'react-router-dom'
import { useDB } from '../context/DBContext';
import Project from '../components/Project';

import './styles/project.css'

export default function ProjectsPage () {

    const navigate = useNavigate();

    const { projects, isLoading, errorMessages } = useDB();

    const handleCreated = () => navigate('/project/create')

    return (

        <>
        
            <div className="__title">
                <h1>Proyectos</h1>
                <button className="__a_btn" onClick={handleCreated}>Nuevo Proyecto</button>
            </div>

            <div className={`__list`} style={{backgroundColor: '#FFFFFF', border: '1px solid #E4E4E4', padding: '1rem'}}>

                <>
                
                    {!isLoading ? (

                        errorMessages === '' ? (

                            projects.length === 0 ? (

                                <h4>No hay blogs publicados aún</h4>

                            ) : (

                                projects.map((p) => (
                                    <Project key={p.id_project} id={p.id_project} slug={p.slug_project} title={p.title_project} status={p.status_project} start={p.start_project} finish={p.finish_project} text={p.text_project} images={p.images} date={p.date_project} />
                                ))

                            )

                        ) : (

                            <h4>{errorMessages}</h4>
                        
                        )

                    ) : (

                        <div className="__skeloton"></div>

                    )}

                </>

            </div>

        </>

    )

}