import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Inbox from "../components/Inbox";

import './styles/inbox.css'

export default function InboxPage () {

    const [ inbox, setInbox ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState('');

    const handleListInbox = async () => {

        try {
            
            setIsLoading(true);

            const response = await fetch(`https://api.northenergyeirl.com/dashboard/?inbox`)

            if (!response.ok) {
                throw new Error("Error requesting inbox from nort readiness");
            }

            const data = await response.json();

            if (data.ok) {
                setInbox(data.inboxes);
            } else {
                setErrorMessage(data.message)
            }

        } catch (error) {
        
            setErrorMessage(error.message)
        
        } finally {

            setIsLoading(false)
        
        }

    }

    useEffect(() => {
        if (inbox.length === 0) {
            handleListInbox();
        }
    }, [inbox.length])

    return (

        <>

            <div className="__title">
                <h1>Inbox</h1>
                <Link to={'https://northenergyeirl.com:2096/cpsess0615030025/login/'} className="__a_btn" target="_blank">Abrir correo</Link>
            </div>

            <div className="__list">

                <>
                
                    {!isLoading ? (

                        errorMessage === '' ? (
                        
                            inbox.map((item) => (
                                <Inbox key={item.id_lead} id={item.id_lead} company={item.name_lead} mail={item.message_lead} service={item.service_lead} email={item.email_lead} phone={item.phone_lead} date={item.date_lead} />
                            ))
                        
                        ) : (

                            <h4>{errorMessage}</h4>
                        
                        )

                    ) : (

                        <div className="__skeleton"></div>

                    )}

                </>

            </div>
        
        </>

    )

}