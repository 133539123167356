import { useEffect, useState } from 'react'
import '../styles/article.css'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { IconArrowLeft, IconPhoto, IconTrash } from '@tabler/icons-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDB } from '../../context/DBContext';
import moment from 'moment';
import { useUI } from '../../context/UIContext';

export default function ProjectPageEdit () {

    const navigate = useNavigate();
    const { id } = useParams();

    const { handleSendAlert } = useUI();
    const { projects, handleListProjects } = useDB();

    const [ files, setFiles ] = useState('');
    const [ title, setTitle ] = useState('');
    const [ status, setStatus ] = useState('');
    const [ date, setDate ] = useState({
        start: '',
        finish: ''
    });
    const [ content, setContent ] = useState('');
    const [ previewUrls, setPreviewUrls ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);

    const handleBack = () => navigate('/project')

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        if (selectedFiles.length <= 5) {
            setFiles(selectedFiles);
            const urls = selectedFiles.map((file) => URL.createObjectURL(file));
            setPreviewUrls(urls); 
        } else {
            handleSendAlert('warning', 'Sube máximo 5 fotos para el blog')
        }
    }

    const handleDeleteImage = (index) => {
        // Filtra las imágenes y archivos que no corresponden al índice clickeado
        const updatedPreviewUrls = previewUrls.filter((_, i) => i !== index);
        const updatedFiles = files.filter((_, i) => i !== index);

        // Actualiza los estados con las imágenes y archivos restantes
        setPreviewUrls(updatedPreviewUrls);
        setFiles(updatedFiles);
    }

    const handleChangeDate = (e) => {
        const { name, value } = e.target
        setDate(prev => ({
            ...prev,
            [name] : value
        }))
    }

    const handleSubmitForm = async (e) => {

        e.preventDefault();

        try {
            
            setIsLoading(true);

            const currentDate = moment().format('YYYY-MM-DD HH:mm:ss');

            const formData = new FormData();
            
            formData.append('id', id);
            formData.append('title', title)
            formData.append('status', status)
            formData.append('start', date.start)
            formData.append('finish', date.finish)
            formData.append('content', content)
            formData.append('date', currentDate)
            files.forEach((file) => {
                formData.append('files[]', file); // Asegúrate de que el backend acepta 'files[]' como array
            });

            const response = await fetch(`https://api.northenergyeirl.com/dashboard/projects/edit.php`, {
                method: 'POST',
                body: formData
            })

            if (!response.ok) {
                throw new Error("Something went wrong");
            }

            const data = await response.json();

            if (data.ok) {
                handleSendAlert('success', 'Se actualizo con éxito el proyecto')
                handleListProjects();
                navigate('/project');
            } else {
                handleSendAlert('warning', 'No se pudo actualizar el proyecto. Intentalo más tarde')
            }

        } catch (error) {
            handleSendAlert('error', 'Hubo un error interno. Intentalo más tarde.')
        } finally {
            setIsLoading(false);
        }

    }

    useEffect(() => {

        const fetchProjects = async () => {
            if (id) {
                try {
                    
                    const response = await fetch(`https://api.northenergyeirl.com/dashboard/projects/?id=${id}`);
                    const data = await response.json();
                    if (data.ok) {
                        const info = data.data;
                        setTitle(info.title)
                        setStatus(info.status)
                        setDate(prev => ({
                            ...prev,
                            start: info.start,
                            finish: info.finish
                        }))
                        setContent(info.text)
                    }

                } catch (error) {
                    handleSendAlert('error', 'No pudimos localizar el proyecto')
                }
            }
        }

        fetchProjects();

    }, [id, projects, handleSendAlert])

    return (

        <>
        
            <div className="__head_form">
                <button onClick={handleBack} className="__btn_back"><IconArrowLeft size={18} /></button>
                <h3>Volver</h3>
            </div>

            <form className="__form" method="POST" encType="multipart/form-data" onSubmit={(e) => handleSubmitForm(e)}>
                <div className="__form_group">
                    <div className="__form_flex">
                        {previewUrls.map((url, index) => (
                            <div className="__previews_proyects" key={index} style={{backgroundImage: `url(${url})`}}>
                                <div className='__btn_delete' onClick={() => handleDeleteImage(index)}><IconTrash size={18} stroke={1.5} /></div>
                            </div>
                        ))}
                        <label htmlFor="files" className="__label_images_proyects"><IconPhoto/></label>
                        <input type="file" name="files" id="files" maxLength={5} multiple onChange={(e) => handleFileChange(e)}/>
                    </div>
                </div>
                <div className="__form_group">
                    <label className="__label" htmlFor="title">Ingresa el titulo del blog</label>
                    <div className="__form_control">
                        <input type="text" className="__entry" name="title" id="title" value={title} placeholder="Ingresa el titulo del blog" aria-placeholder="Ingresa el titulo del blog" onChange={(e) => setTitle(e.target.value)} />
                    </div>
                </div>

                <div className="__form_group">
                    <label className="__label" htmlFor="status">Selecciona el estado del proyecto</label>
                    <div className="__form_control">
                        <select className="__select" name="status" id="status" defaultValue={''} onChange={(e) => setStatus(e.target.value)}>
                            <option value={''} disabled>Selecciona el estado del proyecto</option>
                            <option value={'1'} selected={status === '1' ? true : false}>En Proceso</option>
                            <option value={'2'} selected={status === '2' ? true : false}>Finalizado</option>
                        </select>
                    </div>
                </div>

                {status !== '' && (
                    <div style={{display: 'flex', gap: '1rem'}}>
                
                        {(status === '1' || status === '2') && (
                            <div className="__form_group">
                                <label className="__label" htmlFor="date_start">Ingresa fecha de inicio del proyecto</label>
                                <div className="__form_control">
                                    <input type="date" className="__entry" name="start" id="date_start" value={date.start} placeholder="Ingresa el titulo del proyecto" onChange={(e) => handleChangeDate(e)}/>
                                </div>
                            </div>
                        )}

                        {status === '2' && (
                            <div className="__form_group">
                                <label className="__label" htmlFor="date_finish">Ingresa fecha de fin del proyecto</label>
                                <div className="__form_control">
                                    <input type="date" className="__entry" name="finish" id="date_finish" value={date.finish} placeholder="Ingresa el titulo del proyecto" onChange={(e) => handleChangeDate(e)}/>
                                </div>
                            </div>
                        )}

                    </div>
                )}

                <div className="__form_group">
                    <label className="__label" htmlFor="content">Escribe el contenido del blog</label>
                    <div className="__form_control">
                        <ReactQuill value={content} onChange={setContent} placeholder="Escribe el contenido del blog" />
                    </div>
                </div>
                <div className="__form_group">
                    <button className="__btn_send">{isLoading ? 'Actualizando...' : 'Actualizar'}</button>
                </div>
            </form>

        </>

    )

}