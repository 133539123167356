import { IconArrowLeft, IconPhoto } from "@tabler/icons-react";
import { useState } from "react";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom"

import 'react-quill/dist/quill.snow.css';
import moment from "moment";
import { useDB } from "../../context/DBContext";
import { useUI } from "../../context/UIContext";

export default function ProjectForm () {

    const navigate = useNavigate();

    const { handleSendAlert } = useUI();
    const { handleListProjects } = useDB();

    const [ files, setFiles ] = useState('');
    const [ title, setTitle ] = useState('');
    const [ status, setStatus ] = useState('');
    const [ date, setDate ] = useState({
        start: '',
        finish: ''
    });
    const [ summary, setSummary ] = useState('');
    const [ content, setContent ] = useState('');
    const [ previewUrls, setPreviewUrls ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);

    const handleBack = () => navigate('/project')

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        if (selectedFiles.length <= 5) {
            setFiles(selectedFiles);
            const urls = selectedFiles.map((file) => URL.createObjectURL(file));
            setPreviewUrls(urls); 
        } else {
            handleSendAlert('warning' ,'Sube máximo 5 fotos para el blog')
        }
    }

    const handleChangeDate = (e) => {
        const { name, value } = e.target
        setDate(prev => ({
            ...prev,
            [name] : value
        }))
    }

    const handleCreatedProject = async (e) => {

        e.preventDefault();

        if (!title || !status || !date.start || !summary || !content) {
            handleSendAlert('warning', 'Por favor, completa todos los campos requeridos.');
            return;
        }

        try {

            setIsLoading(true)
            
            const currentDate = moment().format('YYYY-MM-DD HH:mm:ss');

            const formData = new FormData()
            files.forEach((file) => {
                formData.append('files[]', file); // Asegúrate de que el backend acepta 'files[]' como array
            });
            formData.append('title', title)
            formData.append('status', status)
            formData.append('start', date.start)
            formData.append('finish', date.finish || null)
            formData.append('summary', summary)
            formData.append('content', content)
            formData.append('date', currentDate)

            const response = await fetch(`https://api.northenergyeirl.com/dashboard/projects/add.php`, {
                method: 'POST',
                body: formData
            })

            if (!response.ok) {
                const errorData = await response.json(); // Capturar el error del backend
                throw new Error(errorData.message || "Algo salió mal");
            }

            const data = await response.json();

            if (data.ok) {
                handleSendAlert('success', 'Se creó con éxito el nuevo proyecto')
                handleListProjects();
                handleBack();
            } else {
                handleSendAlert('warning', 'No se pudo crear el proyecto. Intentalo más tarde.')
            }

        } catch (error) {
            handleSendAlert('error', 'Hubo un error interno. Intentalo más tarde')
        } finally {
            setIsLoading(false)
        }

    }

    return (

        <>
        
            <div className="__head_form">
                <button onClick={handleBack} className="__btn_back"><IconArrowLeft size={18} /></button>
                <h3>Volver</h3>
            </div>

            {isLoading ? (
                
                <div className="__loader_box">
                    <span className="__loader"></span>
                </div>

            ) : (

                <form className="__form" method="POST" encType="multipart/form-data">

                    <div className="__form_group">
                        {files.length === 0 ? (
                            <>
                                <label htmlFor="files" className="__label_images_proyects"><IconPhoto/></label>
                                <input type="file" name="files" id="files" maxLength={5} multiple onChange={(e) => handleFileChange(e)}/>
                            </>
                        ) : (
                            <div className="__form_flex">
                                {previewUrls.map((url, index) => (
                                    <div className="__previews_proyects" key={index} style={{backgroundImage: `url(${url})`}}></div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="__form_group">
                        <label className="__label" htmlFor="title">Ingresa el titulo del proyecto</label>
                        <div className="__form_control">
                            <input type="text" className="__entry" name="title" id="title" value={title} placeholder="Ingresa el titulo del proyecto" onChange={(e) => setTitle(e.target.value)} />
                        </div>
                    </div>

                    <div className="__form_group">
                        <label className="__label" htmlFor="status">Selecciona el estado del proyecto</label>
                        <div className="__form_control">
                            <select className="__select" name="status" id="status" defaultValue={''} onChange={(e) => setStatus(e.target.value)}>
                                <option value={''} disabled>Selecciona el estado del proyecto</option>
                                <option value={'1'}>En Proceso</option>
                                <option value={'2'}>Finalizado</option>
                            </select>
                        </div>
                    </div>

                    {status !== '' && (
                        <div style={{display: 'flex', gap: '1rem'}}>
                    
                            {(status === '1' || status === '2') && (
                                <div className="__form_group">
                                    <label className="__label" htmlFor="date_start">Ingresa fecha de inicio del proyecto</label>
                                    <div className="__form_control">
                                        <input type="date" className="__entry" name="start" id="date_start" placeholder="Ingresa el titulo del proyecto" onChange={(e) => handleChangeDate(e)}/>
                                    </div>
                                </div>
                            )}

                            {status === '2' && (
                                <div className="__form_group">
                                    <label className="__label" htmlFor="date_finish">Ingresa fecha de fin del proyecto</label>
                                    <div className="__form_control">
                                        <input type="date" className="__entry" name="finish" id="date_finish" placeholder="Ingresa el titulo del proyecto" onChange={(e) => handleChangeDate(e)}/>
                                    </div>
                                </div>
                            )}

                        </div>
                    )}

                    <div className="__form_group">
                        <label className="__label" htmlFor="summary">Ingresa un resumen del proyecto</label>
                        <div className="__form_control">
                            <textarea className="__textbox" name="summary" id="summary" placeholder="Ingresa un resumen del proyecto" aria-placeholder="Ingresa un resumen del proyecto" onChange={(e) => setSummary(e.target.value)} />
                        </div>
                    </div>

                    <div className="__form_group">
                        <label className="__label" htmlFor="content">Redacta el proyecto</label>
                        <div className="__form_control">
                            <ReactQuill value={content} onChange={setContent} placeholder="Redacta el proyecto" />
                        </div>
                    </div>

                    <div className="__form_group">
                        <button className="__btn_send" onClick={(e) => handleCreatedProject(e)}>{isLoading ? 'Subiendo...' : 'Subir'}</button>
                    </div>

                </form>

            )}

        </>

    )

}