import { useEffect, useState } from "react"
import Mails from "../components/Mails";

import './styles/mails.css'

export default function MailsPage () {

    const [ mails, setMails ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState('');

    const skeletons = Array(5).fill('');

    const handleListMails = async () => {
        
        try {
            
            setIsLoading(true);

            const response = await fetch(`https://api.northenergyeirl.com/dashboard/?mails`);

            if (!response.ok) {
                throw new Error('Failed to fetch Mail List from Server')
            }

            const data = await response.json();

            if (data.ok) {
                setMails(data.mails)
            } else {
                setErrorMessage(data.message)
            }

        } catch (error) {

            setErrorMessage(error.message)
        
        } finally {
        
            setIsLoading(false);
        
        }

    }

    useEffect(() => {
        if (mails.length === 0) {
            handleListMails();
        }
    }, [mails.length]);

    return (

        <>
        
            <div className="__title">
                <h1>Mails</h1>
            </div>

            <div className="__list">

                <>

                    {!isLoading ? (

                        errorMessage === '' ? (
                            mails.map((mail) => (
                                <Mails key={mail.id_new} id={mail.id_new} email={mail.email_new} date={mail.date_new} />
                            ))
                        ) : (
                            <h4>{errorMessage}</h4>
                        )

                    ) : (

                        skeletons.map((_, index) => (
                            <div className="__skeleton" key={index}></div>
                        ))

                    )}

                </>

            </div>

        </>

    )

}