import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import './assets/css/variables.css';
import './assets/css/global.css'
import LayoutPage from './pages/LayoutPage';
import ProjectsPage from './pages/ProjectsPage';
import ArticlePage from './pages/ArticlePage';
import InboxPage from './pages/InboxPage';
import MailsPage from './pages/MailsPage';
import { UIProvider } from './context/UIContext';
import { DBProvider } from './context/DBContext';
import ArticlePageEdit from './pages/(edits)/ArticlePageEdit';
import ArticleForm from './components/Forms/Article';
import HomePage from './pages/HomePage';
import ProjectForm from './components/Forms/Project';
import ProjectPageEdit from './pages/(edits)/ProjectPageEdit';
import ErrorPage from './pages/ErrorPage';

const router = createBrowserRouter([
    {
        path: '/',
        element: <LayoutPage/>,
        children: [
            {
                path: '/',
                element: <HomePage/>
            },
            {
                path: '/project',
                children: [
                    {
                        path: '',
                        element: <ProjectsPage/>
                    },
                    {
                        path: 'create',
                        element: <ProjectForm/>
                    },
                    {
                        path: 'edit/:id',
                        element: <ProjectPageEdit/>
                    }
                ]
            },
            {
                path: '/article',
                children: [
                    {
                        path: '',
                        element: <ArticlePage/>
                    },
                    {
                        path: 'create',
                        element: <ArticleForm/>
                    },
                    {
                        path: 'edit/:id',
                        element: <ArticlePageEdit/>
                    }
                ]
            },
            {
                path: '/inbox',
                element: <InboxPage/>
            },
            {
                path: '/mails',
                element: <MailsPage/>
            }
        ],
        errorElement: <ErrorPage/>
    }
])

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    
    <UIProvider>
        
        <DBProvider>
            
            <RouterProvider router={router} />
        
        </DBProvider>
    
    </UIProvider>

);