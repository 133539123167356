import { Link, useLocation } from 'react-router-dom'
import './styles/header.css'
import { IconArchive, IconArchiveFilled, IconArticle, IconArticleFilled, IconBulb, IconBulbFilled, IconHome, IconHomeFilled, IconMail, IconMailFilled } from '@tabler/icons-react'

export default function Header () {

    const location = useLocation();

    return (

        <header className="__header" id="header">

            <div className="__box_header">

                <nav className='__nav'>
                    <ul className='__nav_list'>
                        <li className='__item_nav'>
                            <Link to={'/'} className={`__a_nav ${location.pathname === '/' ? '__a_nav--active' : ''}`}>
                                {location.pathname === '/' ? <IconHomeFilled/> : <IconHome/>}
                            </Link>
                        </li>
                        <li className='__item_nav'>
                            <Link to={'/project'} className={`__a_nav ${location.pathname === '/project' ? '__a_nav--active' : ''}`}>
                                {location.pathname === '/project' ? <IconBulbFilled/> : <IconBulb/>}
                            </Link>
                        </li>
                        <li className='__item_nav'>
                            <Link to={'/article'} className={`__a_nav ${location.pathname === '/article' ? '__a_nav--active' : ''}`}>
                                {location.pathname === '/article' ? <IconArticleFilled/> : <IconArticle/> }
                            </Link>
                        </li>
                        <li className='__item_nav'>
                            <Link to={'/inbox'} className={`__a_nav ${location.pathname === '/inbox' ? '__a_nav--active' : ''}`}>
                                {location.pathname === '/inbox' ? <IconArchiveFilled/> : <IconArchive/>}
                            </Link>
                        </li>
                        <li className='__item_nav'>
                            <Link to={'/mails'} className={`__a_nav ${location.pathname === '/mails' ? '__a_nav--active' : ''}`}>
                                {location.pathname === '/mails' ? <IconMailFilled/> : <IconMail/>}
                            </Link>
                        </li>
                    </ul>
                </nav>

            </div>

        </header>

    )

}