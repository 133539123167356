import { useEffect, useState } from 'react'
import '../styles/article.css'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { IconArrowLeft, IconPhoto } from '@tabler/icons-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDB } from '../../context/DBContext';
import moment from 'moment';
import { useUI } from '../../context/UIContext';

export default function ArticlePageEdit () {

    const navigate = useNavigate();
    const { id } = useParams();

    const { handleSendAlert } = useUI();
    const { articles, handleListArticles } = useDB();

    const [ files, setFiles ] = useState('');
    const [ title, setTitle ] = useState('');
    const [ content, setContent ] = useState('');
    const [ previewUrls, setPreviewUrls ] = useState([]);

    const [ isLoading, setIsLoading ] = useState(false);

    const handleBack = () => navigate('/article')

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        if (selectedFiles.length <= 5) {
            setFiles(selectedFiles);
            const urls = selectedFiles.map((file) => URL.createObjectURL(file));
            setPreviewUrls(urls); 
        } else {
            handleSendAlert('warning', 'Sube máximo 5 fotos para el blog')
        }
    }

    const handleSubmitForm = async (e) => {

        e.preventDefault();

        try {
            
            setIsLoading(true);

            const currentDate = moment().format('YYYY-MM-DD HH:mm:ss');

            const formData = new FormData();
            
            files.forEach((file) => {
                formData.append('files[]', file); // Asegúrate de que el backend acepta 'files[]' como array
            });
            formData.append('title', title)
            formData.append('content', content)
            formData.append('date', currentDate)
            formData.append('id', id);

            const response = await fetch(`https://api.northenergyeirl.com/dashboard/blog/edit.php`, {
                method: 'POST',
                body: formData
            })

            if (!response.ok) {
                throw new Error("Something went wrong");
            }

            const data = await response.json();

            if (data.ok) {
                handleSendAlert('success', 'Se actulizo con éxito el blog')
                handleListArticles();
                navigate('/article');
            } else {
                handleSendAlert('warning', 'No se pudo actulizar el blog. Intentalo más tarde.')
            }

        } catch (error) {
            handleSendAlert('error', 'Hubo un error interno. Intentalo más tarde.')
        } finally {
            setIsLoading(false);
        }

    }

    useEffect(() => {
        const fetchArticlesAndSet = async () => {
            if (id) {
                const article = articles.find((blog) => blog.id_blog === id); // Buscar el artículo
                if (article) {
                    setTitle(article.title_blog);
                    setContent(article.text_blog);
                }
            }
        };
    
        fetchArticlesAndSet();
    }, [id, articles])

    return (

        <>
            <div className="__head_form">
                <button onClick={handleBack} className="__btn_back"><IconArrowLeft size={18} /></button>
                <h3>Volver</h3>
            </div>

            <form className="__form" method="POST" encType="multipart/form-data" onSubmit={(e) => handleSubmitForm(e)}>
                <div className="__form_group">
                    {files.length === 0 ? (
                        <>
                            <label htmlFor="files" className="__label_images"><IconPhoto/></label>
                            <input type="file" name="files" id="files" maxLength={5} multiple onChange={(e) => handleFileChange(e)}/>
                        </>
                    ) : (
                        <div className="__form_flex">
                            {previewUrls.map((url, index) => (
                                <div className="__previews" key={index} style={{backgroundImage: `url(${url})`}}></div>
                            ))}
                        </div>
                    )}
                </div>
                <div className="__form_group">
                    <label className="__label" htmlFor="title">Ingresa el titulo del blog</label>
                    <div className="__form_control">
                        <input type="text" className="__entry" name="title" id="title" value={title} placeholder="Ingresa el titulo del blog" aria-placeholder="Ingresa el titulo del blog" onChange={(e) => setTitle(e.target.value)} />
                    </div>
                </div>

                <div className="__form_group">
                    <label className="__label" htmlFor="content">Escribe el contenido del blog</label>
                    <div className="__form_control">
                        <ReactQuill value={content} onChange={setContent} placeholder="Escribe el contenido del blog" />
                    </div>
                </div>
                <div className="__form_group">
                    <button className="__btn_send">{isLoading ? 'Actualizando...' : 'Actualizar'}</button>
                </div>
            </form>
        </>

    )

}