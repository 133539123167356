import { Outlet } from 'react-router-dom'
import Header from '../layout/Header'
import './styles/layout.css'
import { useUI } from '../context/UIContext'
import Alert from '../components/Alert';

export default function LayoutPage () {

    const { isAlert } = useUI();

    return (

        <>
        
            <div className='__content_app'>

                <Header/>

                <main className='__main' id='main'>
                    <Outlet/>
                </main>

            </div>

            {isAlert.message !== '' && (
                <Alert type={isAlert.type} message={isAlert.message} />
            )}

        </>

    )

}