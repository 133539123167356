import Networks from '../components/Networks'
import './styles/home.css'

export default function HomePage () {

    return (

        <>
            
            <div className="__title">
                <h1 style={{textAlign: 'center'}}>Bienvenido al Dashboard</h1>
            </div>

            <div className="__list">
                <Networks/>
            </div>

        </>

    )

}