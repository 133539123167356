import { IconArrowsDiagonal, IconArrowsDiagonalMinimize2 } from "@tabler/icons-react";
import moment from "moment";
import 'moment/locale/es';
import { useState } from "react";

moment.locale('es');

export default function Inbox({ id, company, service, mail, email, phone, date }) {

    const relativeTime = moment(date).fromNow();

    const formattedDate = moment(date).format('LL')

    const [ expanded, setExpanded ] = useState({
        id: '',
        open: false
    })

    return (

        <>

            <div className="__inbox_box">

                <div className="__box_in">
                    <div className="__col1">

                        <figure className="__image">
                            <img src={`https://ui-avatars.com/api/?name=${company}&size=50`} alt={`Avatar de la empresa ${company}`} />
                        </figure>
                        <div>
                            <h3>{company} • {service}</h3>
                            <p>{email} • {relativeTime}</p>
                        </div>

                    </div>
                    <div className="__col2">

                        <button className="__btn_submenu" onClick={() => setExpanded({id: id, open: !expanded.open})}>{expanded.open ? <IconArrowsDiagonalMinimize2/> : <IconArrowsDiagonal/>}</button>
                    
                    </div>
                </div>

                {(expanded.open && expanded.id === id) && (
                    <div className="__box_on">
                        <h3 style={{marginBottom: '1rem'}}>Servicio: {service}</h3>
                        <p className="__messg">{mail}</p>
                        <div className="__cnt">
                            <p>De: {company}</p>
                            <p>Número: {phone}</p>
                            <p>Correo: {email}</p>
                            <p>Fecha: {formattedDate}</p>
                        </div>
                    </div>
                )}

            </div>
        
        </>

    )

}