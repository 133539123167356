import { IconArrowsDiagonal, IconArrowsDiagonalMinimize2, IconDots, IconEdit, IconTrash } from "@tabler/icons-react"
import moment from "moment";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom"
import { useDB } from "../context/DBContext";

export default function Project ({ id, slug, title, status, start, finish, text, images, date }) {

    const navigate = useNavigate();

    const { handleListProjects } = useDB();

    const [ viewMenu, setViewMenu ] = useState(false);
    const [ isDelete, setIsDelete ] = useState(false);
    const [ isExpanded, setIsExpanded ] = useState({
        open: false,
        id: '',
    });

    const [ isDeleting, setIsDeleting ] = useState(false);

    const estado = status === '1' ? 'En Proceso' : 'Finalizado';

    const relativeTime = moment(date).fromNow();

    const handleOpenNav = (navID) => setViewMenu(navID === id && !viewMenu)

    const handleExpended = (pID) => {
        handleOpenNav(pID);
        setIsExpanded({open: !isExpanded.open, id: pID})
    }

    const handleDelete = async (id) => {

        try {
            
            setIsDeleting(true);

            const formData = new FormData();
            formData.append('id', id);

            const response = await fetch(`https://api.northenergyeirl.com/dashboard/projects/delete.php`, {
                method: 'POST',
                body: formData
            })

            if (!response.ok) {
                throw new Error('Error deleting blog');
            }

            const data = await response.json();

            if (data.ok) {
                handleListProjects();
            }

        } catch (error) {
            console.log(error.message);            
        } finally {
            setIsDeleting(false);
        }
    }

    return (

        <div className="__project">

            <div className="__project_head">

                <div className="__col1">
                    <h3><Link target="_blank" to={`https://northenergyeirl.com/projects/${slug}`}>{title}</Link></h3>
                    <div style={{display: 'flex', alignItems: 'center', gap: '.3rem'}}>
                        <p className={`__badge_status __badge_status_${status === '1' ? 'processing' : 'finished'}`}>{estado}</p>
                        <p style={{fontSize: '.8rem', color: '#888888'}}> | Publicado {relativeTime}</p>
                    </div>
                </div>

                <div className="__col2">
                    <button className='__btn_submenu' onClick={() => handleOpenNav(id)}><IconDots/></button>
                    <ul className={`__subnav ${viewMenu ? '__subnav--active' : ''}`} style={{width: isDelete ? '250px' : '180px'}}>
                        {!isDelete ? (
                            <>
                                <li className="__item_subnav" onClick={() => handleExpended(id)}>
                                    <span className="__ico_subnav">{isExpanded.open ? <IconArrowsDiagonalMinimize2 size={18} strokeWidth={1.5} stroke={'#172BDE'}/> : <IconArrowsDiagonal size={18} strokeWidth={1.5} stroke={'#172BDE'}/>}</span>
                                    <span>{isExpanded.open ? 'Minimizar': 'Maximizar'}</span>
                                </li>
                                <li className="__item_subnav" onClick={() => navigate(`/project/edit/${id}`)}>
                                    <span className="__ico_subnav"><IconEdit size={18} strokeWidth={1.5} stroke={'#172BDE'}/></span>
                                    <span>Editar</span>
                                </li>
                                <hr/>
                                <li className="__item_subnav __item_subnav_danger" onClick={() => setIsDelete(true)}>
                                    <span className="__ico_subnav"><IconTrash size={18} strokeWidth={1.5} stroke={'#FF0000'}/></span>
                                    <span>Eliminar</span>
                                </li>
                            </>
                        ) : (
                            <>
                                <p style={{textAlign: 'center'}}>¿Estas seguro de que quieres eliminar este blog?</p>
                                <button className="__item_subnav __item_subnav_danger" style={{height: '40px', justifyContent: 'center'}} onClick={() => handleDelete(id)}>{isDeleting ? 'Eliminando': 'Si, eliminar'}</button>
                                <button className="__item_subnav" style={{height: '40px', backgroundColor: '#F1F1F1', justifyContent: 'center'}} onClick={() => setIsDelete(false)}>Cancelar</button>
                            </>
                        )}
                    </ul>
                </div>

            </div>

            {(isExpanded.open && isExpanded.id === id) && (
                <div className="__project_body">
                    <div dangerouslySetInnerHTML={{__html: text}}></div>
                    <div className="__images">
                        {images.map((img, index) => (
                            <div className="__img" key={index} style={{backgroundImage: `url(${img})`}}></div>
                        ))}
                    </div>
                </div>
            )}

        </div>

    )

}