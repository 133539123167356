import { IconArrowsDiagonal, IconArrowsDiagonalMinimize2, IconDots, IconEdit, IconTrash } from "@tabler/icons-react";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDB } from "../context/DBContext";
import { useUI } from "../context/UIContext";

export default function Articles ({ id, title, text, date, images }) {

    const navigate = useNavigate();

    const { handleSendAlert } = useUI();
    const { handleListArticles } = useDB();

    const [ activeSubmenu, setActiveSubmenu ] = useState(false);
    const [ isDelete, setIsDelete ] = useState(false);
    const [ isExpanded, setIsExpanded ] = useState({
        open: false,
        id: '',
    });

    const relativeTime = moment(date).fromNow();

    const handleOpenSubmenu = (tkn) => setActiveSubmenu(tkn === id && !activeSubmenu)

    const handleExpended = (pID) => {
        handleOpenSubmenu(pID);
        setIsExpanded({open: !isExpanded.open, id: pID})
    }

    const handleDelete = async (id) => {

        try {
            
            const formData = new FormData();
            formData.append('id', id);

            const response = await fetch(`https://api.northenergyeirl.com/dashboard/blog/delete.php`, {
                method: 'POST',
                body: formData
            })

            if (!response.ok) {
                throw new Error('Error deleting blog');
            }

            const data = await response.json();

            if (data.ok) {
                handleSendAlert('success', 'Se eliminó con éxito el articulo del blog.')
                handleListArticles();
            } else {
                handleSendAlert('warning', 'No se pudo eliminar el blog. Intentalo más tarde')
            }

        } catch (error) {
            handleSendAlert('error', 'Hubo un error interno. Intentalo más tarde')        
        }

    }

    return (

        <div className="__article">

            <div className="__article_head">
                <div className="__col1">
                    <h3>{title}</h3>
                    <p>Publicado {relativeTime}</p>
                </div>
                <div className="__col2">
                    <button className="__btn_submenu" onClick={() => handleOpenSubmenu(id)}><IconDots/></button>
                    <ul className={`__subnav ${activeSubmenu ? '__subnav--active' : ''}`} style={{width: isDelete ? '250px' : '180px'}}>
                        {!isDelete ? (
                            <>
                                <li className="__item_subnav" onClick={() => handleExpended(id)}>
                                    <span className="__ico_subnav">{isExpanded.open ? <IconArrowsDiagonalMinimize2 size={18} strokeWidth={1.5} stroke={'#172BDE'}/> : <IconArrowsDiagonal size={18} strokeWidth={1.5} stroke={'#172BDE'}/>}</span>
                                    <span>{isExpanded.open ? 'Minimizar': 'Maximizar'}</span>
                                </li>
                                <li className="__item_subnav" onClick={() => navigate(`/article/edit/${id}`)}>
                                    <span className="__ico_subnav"><IconEdit size={18} strokeWidth={1.5} stroke={'#172BDE'}/></span>
                                    <span>Editar</span>
                                </li>
                                <hr/>
                                <li className="__item_subnav __item_subnav_danger" onClick={() => setIsDelete(true)}>
                                    <span className="__ico_subnav"><IconTrash size={18} strokeWidth={1.5} stroke={'#FF0000'}/></span>
                                    <span>Eliminar</span>
                                </li>
                            </>
                        ) : (

                            <>
                            
                                <p style={{textAlign: 'center'}}>¿Estas seguro de que quieres eliminar este blog?</p>
                                <button className="__item_subnav __item_subnav_danger" style={{height: '40px', justifyContent: 'center'}} onClick={() => handleDelete(id)}>Si, eliminar</button>
                                <button className="__item_subnav" style={{height: '40px', backgroundColor: '#F1F1F1', justifyContent: 'center'}} onClick={() => setIsDelete(false)}>Cancelar</button>

                            </>

                        )}
                    </ul>
                </div>
            </div>
            {isExpanded.open && isExpanded.id === id && (
                <div className="__article_body">
                    <div dangerouslySetInnerHTML={{__html: text}}></div>
                    <div className="__images">
                        {images.map((image, index) => (
                            <div className="__img" key={index} style={{backgroundImage: `url(${image})`}}></div>
                        ))}
                    </div>
                </div>
            )}

        </div>

    )

}