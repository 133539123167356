import { useDB } from "../context/DBContext";
import { useNavigate } from "react-router-dom";
import Articles from "../components/Articles";

import './styles/article.css'

export default function ArticlePage () {

    const navigate = useNavigate();

    const { articles, isLoading, errorMessages } = useDB();

    const handleCreated = () => navigate('/article/create')

    return (

        <>
        
            <div className="__title">
                <h1>Blog</h1>
                <button className="__a_btn" onClick={handleCreated}>Nuevo Blog</button>
            </div>

            <div className={`__list`} style={{backgroundColor: '#FFFFFF', border: '1px solid #E4E4E4', padding: '1rem'}}>

                <>
                        
                    {!isLoading ? (

                        errorMessages === '' ? (

                            articles.length === 0 ? (
                                
                                <h4>No hay blogs publicados aún</h4>
                            
                            ) : (

                                articles.map((article) => (

                                    <Articles key={article.id_blog} id={article.id_blog} title={article.title_blog} text={article.text_blog} date={article.date_blog} images={article.images} />
    
                                ))
                            )

                        ) : (

                            <h4>{errorMessages}</h4>

                        )

                    ) : (

                        <div className="__skeloton"></div>

                    )}

                </>

            </div>

        </>

    )

}