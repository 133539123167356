import { IconBrandFacebook, IconBrandLinkedin, IconBrandWhatsapp, IconCheck, IconEdit, IconMail } from "@tabler/icons-react";
import { useState } from "react";
import { useDB } from "../context/DBContext";

export default function Networks () {

    const { redes, handleListRedes } = useDB();

    const [ isDisabled, setIsDisabled ] = useState({
        red: '',
        disabled: true
    })

    const [ data, setData ] = useState({
        fb: '',
        in: '',
        ml: '',
        wh: ''
    })

    const handleChangeData = (e) => {
        const { name, value } = e.target
        setData(prev => ({
            ...prev,
            [ name ] : value
        }))
        console.log(value);
    }

    const handleSendSocial = async (name) => {
        
        const url = data[name];

        try {
            
            const formData = new FormData();
            formData.append('name', name);
            formData.append('url', url)

            const response = await fetch(`https://api.northenergyeirl.com/dashboard/network/add.php`, {
                method: 'POST',
                body: formData
            })

            if (!response.ok) {
                throw new Error('Failed to add network to dashboard')
            }

            const data = await response.json()

            if (data.ok) {
                setIsDisabled({
                    red: '',
                    disabled: true
                })
                handleListRedes();
            } else {
                console.log(data)
            }

        } catch (error) {
            console.log(error.message);
        }

    }

    return (

        <>
        
            <div className="__net">
                <h3>Redes sociales</h3>
                <div className="__line"></div>

                <div className="__network">
                    <span className="__ico"><IconBrandFacebook strokeWidth={1.2} /></span>
                    <input type="text" className="__entry" name="fb" id="fb" placeholder={`${redes.fb !== '' ? redes.fb : 'Ingresa el URL o usuario de la red social'}`} onChange={(e) => handleChangeData(e)} disabled={isDisabled.red !== 'fb' || isDisabled.red === '' ? true : false} />
                    {isDisabled.red === 'fb' && !isDisabled.disabled ? (
                        <button className="__btn" onClick={() => handleSendSocial('fb')}><IconCheck strokeWidth={1.2}/></button>
                    ) : (
                        <button className="__btn" onClick={() => setIsDisabled({red: 'fb', disabled: false})}><IconEdit strokeWidth={1.2}/></button>  
                    )}
                </div>

                <div className="__network">
                    <span className="__ico"><IconBrandLinkedin strokeWidth={1.2} /></span>
                    <input type="text" className="__entry" name="in" id="in" placeholder={`${redes.in !== '' ? redes.in : 'Ingresa el URL o usuario de la red social'}`} onChange={(e) => handleChangeData(e)} disabled={isDisabled.red !== 'in' || isDisabled.red === '' ? true : false} />
                    {isDisabled.red === 'in' && !isDisabled.disabled ? (
                        <button className="__btn" onClick={() => handleSendSocial('in')}><IconCheck strokeWidth={1.2}/></button>
                    ) : (
                        <button className="__btn" onClick={() => setIsDisabled({red: 'in', disabled: false})}><IconEdit strokeWidth={1.2}/></button>  
                    )}
                </div>

                <div className="__network">
                    <span className="__ico"><IconMail strokeWidth={1.2} /></span>
                    <input type="text" className="__entry" name="ml" id="ml" placeholder={`${redes.ml !== '' ? redes.ml : 'Ingresa el URL o usuario de la red social'}`} onChange={(e) => handleChangeData(e)} disabled={isDisabled.red !== 'ml' || isDisabled.red === '' ? true : false} />
                    {isDisabled.red === 'ml' && !isDisabled.disabled ? (
                        <button className="__btn" onClick={() => handleSendSocial('ml')}><IconCheck strokeWidth={1.2}/></button>
                    ) : (
                        <button className="__btn" onClick={() => setIsDisabled({red: 'ml', disabled: false})}><IconEdit strokeWidth={1.2}/></button>  
                    )}
                </div>

                <div className="__network">
                    <span className="__ico"><IconBrandWhatsapp strokeWidth={1.2} /></span>
                    <input type="text" className="__entry" name="wh" id="wh" placeholder={`${redes.wh !== '' ? redes.wh : 'Ingresa el URL o usuario de la red social'}`} onChange={(e) => handleChangeData(e)} disabled={isDisabled.red !== 'wh' || isDisabled.red === '' ? true : false} />
                    {isDisabled.red === 'wh' && !isDisabled.disabled ? (
                        <button className="__btn" onClick={() => handleSendSocial('wh')}><IconCheck strokeWidth={1.2}/></button>
                    ) : (
                        <button className="__btn" onClick={() => setIsDisabled({red: 'wh', disabled: false})}><IconEdit strokeWidth={1.2}/></button>  
                    )}
                </div>

            </div>

        </>

    )

}