import { IconX } from "@tabler/icons-react";
import { useUI } from "../context/UIContext";
import { useCallback, useEffect } from "react";

export default function Alert ({ type, message }) {

    const { handleSendAlert } = useUI();

    const handleClosingAlert = useCallback(() => {
        handleSendAlert('', '');
    }, [handleSendAlert]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            handleClosingAlert();
        }, 3000);

        return () => clearTimeout(timeoutId);
    }, [handleClosingAlert])

    return (

        <div className={`__alert __alert_${type}`}>
            <p>{message}</p>
            <button className="__btn_close" onClick={handleClosingAlert}><IconX size={18} /></button>
        </div>

    )

}